import * as React from "react";

import { Button, Grid } from "@material-ui/core";
import {
  Create,
  Datagrid,
  DateField,
  DateInput,
  DeleteButton,
  Edit,
  EditButton,
  FormDataConsumer,
  FormWithRedirect,
  List,
  NumberField,
  NumberInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import firebase from "firebase/app";

import { CURRENCY } from "../config";

export const DiscountCodeList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="code" />
      <NumberField
        source="discount"
        options={{ style: "currency", currency: CURRENCY }}
      />
      <NumberField
        source="min_price"
        options={{ style: "currency", currency: CURRENCY }}
      />
      <NumberField source="count_of_used" />
      <NumberField source="limit_of_used" />
      <DateField source="expiration" />
      <DeleteButton label="" />
      <EditButton label="" />
    </Datagrid>
  </List>
);

const frontendOrigin = location.origin.replace("admin.", "");
const functionUrl = frontendOrigin + "/__ext/discount-codes/generate";

export const DiscountCodeCreate = props => (
  <Create {...props}>
    <FormWithRedirect
      render={() => (
        <form>
          <Grid container direction="column" style={{ margin: 20 }}>
            <Grid item>
              <NumberInput source="count" required label="Počet kupónů k vygenerování" />
            </Grid>
            <Grid item>
              <RichTextInput source="description" required label="Popis" />
            </Grid>
            <Grid item>
              <NumberInput source="discount" required label="Sleva (částka)" />
            </Grid>
            <Grid item>
              <NumberInput
                source="min_price"
                required
                label="Min. cena objednávky"
              />
            </Grid>
            <Grid item>
              <NumberInput
                source="limit"
                required
                label="Max. počet použití kupónu"
              />
            </Grid>
            <Grid item>
              <DateInput source="expiration" label="Expirace" />
            </Grid>
          </Grid>
          <Toolbar>
            <FormDataConsumer>
              {({ formData }) => (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={async () =>
                    window.open(
                      functionUrl +
                        "?" +
                        new URLSearchParams({
                          ...formData,
                          token: await firebase
                            .auth()
                            .currentUser?.getIdToken(),
                        })
                    )
                  }
                >
                  Generovat
                </Button>
              )}
            </FormDataConsumer>
          </Toolbar>
        </form>
      )}
    />
  </Create>
);

export const DiscountCodeEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="code" required />
      <NumberInput source="discount" required />
      <NumberInput source="min_price" required />
      <NumberInput source="count_of_used" required />
      <NumberInput source="limit_of_used" required />
      <DateInput source="expiration" />
    </SimpleForm>
  </Edit>
);

import * as React from "react";

import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  List,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import SearchFilter from "../SearchFilter";

const CategoryFilter = props => (
  <SearchFilter {...props} searchSources={["title"]}>
    <SearchInput source="title" alwaysOn />
    <BooleanInput source="status" />
    <ReferenceInput source="parent_id" reference="categories">
      <SelectInput optionText="title" />
    </ReferenceInput>
  </SearchFilter>
);

export const CategoryList = props => (
  <List {...props} filters={<CategoryFilter />}>
    <Datagrid>
      <BooleanField source="status" />
      <TextField source="title" />
      <ReferenceField source="parent_id" reference="categories">
        <TextField source="title" />
      </ReferenceField>
      <EditButton label="" />
      <DeleteButton label="" redirect={false} />
    </Datagrid>
  </List>
);

export const CategoryCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput label="Status" source="status" />
      <TextInput source="title" />
      <ReferenceInput source="parent_id" reference="categories">
        <SelectInput optionText="title" />
      </ReferenceInput>
      <TextInput
        source="ext_zbozicz_categorytext"
        helperText="viz https://napoveda.seznam.cz/cz/specifikace-xml-feedu/#CATEGORYTEXT"
        fullWidth
      />
    </SimpleForm>
  </Create>
);

export const CategoryEdit = props => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <BooleanInput label="Status" source="status" />
      <TextInput source="title" />
      <ReferenceInput source="parent_id" reference="categories">
        <SelectInput optionText="title" />
      </ReferenceInput>
      <TextInput
        source="ext_zbozicz_categorytext"
        helperText="viz https://napoveda.seznam.cz/cz/specifikace-xml-feedu/#CATEGORYTEXT"
        fullWidth
      />
    </SimpleForm>
  </Edit>
);

import React from "react";

import { Admin, Resource } from "react-admin";
import {
  AttachMoney,
  BusinessCenter,
  Folder,
  InsertDriveFile,
  LocalShipping,
  Payment,
  Redeem,
  Store,
} from "@material-ui/icons";
import { FirebaseAuthProvider } from "react-admin-firebase";
import { createMuiTheme } from "@material-ui/core/styles";
import hasuraDataProvider from "ra-data-hasura";
import polyglotI18nProvider from "ra-i18n-polyglot";

import "firebase/auth";
import "firebase/storage";
import firebase from "firebase/app";

import {
  CategoryCreate,
  CategoryEdit,
  CategoryList,
} from "./resource/categories";
import {
  DiscountCodeCreate,
  DiscountCodeEdit,
  DiscountCodeList,
} from "./resource/discountCode";
import {
  ManufacturersCreate,
  ManufacturersEdit,
  ManufacturersList,
} from "./resource/manufacturers";
import { OrderEdit, OrderList } from "./resource/orders";
import { OrderProductEdit } from "./resource/order_product";
import { PaymentCreate, PaymentEdit, PaymentList } from "./resource/payment";
import { PostCreate, PostEdit, PostList } from "./resource/posts";
import { ProductCreate, ProductEdit, ProductList } from "./resource/products";
import {
  ShippingCreate,
  ShippingEdit,
  ShippingList,
} from "./resource/shipping";
import FirebaseStorageProvider from "./FirebaseStorageProvider";
import czechMessages from "./i18n/czech";
import firebaseConfig from "./firebase-config.json";

const firebaseApp = firebase.initializeApp(firebaseConfig);

const options = {
  logging: true,
  app: firebaseApp,
};

const hostname = window.location.hostname;
const gqlEndpoint =
  window.location.protocol +
  "//" +
  (hostname.startsWith("admin.") ? hostname.replace(/^admin\./, "") : hostname);

const authProvider = FirebaseAuthProvider(firebaseConfig, options);
const dataProvider = FirebaseStorageProvider(
  hasuraDataProvider(gqlEndpoint, async (url: string, options: RequestInit) => {
    options.headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (await authProvider.getJWTToken()),
    };
    return { json: await (await fetch(url, options)).json() };
  })
);

const i18nProvider = polyglotI18nProvider(() => czechMessages, "cs");

const theme = createMuiTheme({
  palette: {
    secondary: { main: "#0277c5" },
  },
  overrides: {
    MuiInputBase: {
      root: { "&.Mui-disabled": { color: "rgba(0,0,0,0.6)" } },
    },
  },
});

const App = () => (
  <Admin
    theme={theme}
    dataProvider={dataProvider}
    authProvider={authProvider}
    i18nProvider={i18nProvider}
  >
    <Resource
      name="orders"
      icon={AttachMoney}
      list={OrderList}
      edit={OrderEdit}
    />
    <Resource
      name="products"
      icon={Store}
      list={ProductList}
      create={ProductCreate}
      edit={ProductEdit}
    />
    <Resource
      name="categories"
      icon={Folder}
      list={CategoryList}
      create={CategoryCreate}
      edit={CategoryEdit}
    />
    <Resource
      name="discount_codes"
      icon={Redeem}
      list={DiscountCodeList}
      create={DiscountCodeCreate}
      edit={DiscountCodeEdit}
    />
    <Resource
      name="posts"
      icon={InsertDriveFile}
      list={PostList}
      create={PostCreate}
      edit={PostEdit}
    />
    <Resource
      name="shipping"
      icon={LocalShipping}
      list={ShippingList}
      create={ShippingCreate}
      edit={ShippingEdit}
    />
    <Resource
      name="payment"
      icon={Payment}
      list={PaymentList}
      create={PaymentCreate}
      edit={PaymentEdit}
    />
    <Resource
      name="manufacturers"
      icon={BusinessCenter}
      list={ManufacturersList}
      create={ManufacturersCreate}
      edit={ManufacturersEdit}
    />
    <Resource name="product_in_category" />
    <Resource name="product_gifts" />
    <Resource name="order_product" edit={OrderProductEdit} />
    {/*<Resource name="order_status" />*/}
    <Resource name="order_update" />
  </Admin>
);

export default App;

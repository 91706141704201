import * as React from "react";
import {
  ArrayInput,
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  NumberField,
  NumberInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
} from "react-admin";

import { CURRENCY } from "../config";

export const ShippingList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="title" />
      <BooleanField source="enable_for_all_products" />
      <NumberField
        source="price"
        options={{ style: "currency", currency: CURRENCY }}
      />
      <EditButton label="" />
    </Datagrid>
  </List>
);

const Form = props => (
  <SimpleForm {...props}>
    <TextInput disabled source="id" />
    <TextInput source="title" required />
    <NumberInput source="price" required />
    <BooleanInput source="enable_for_all_products" />
    <ArrayInput source="ext_heureka_delivery">
      <SimpleFormIterator>
        <TextInput
          source="delivery_id"
          helperText="viz https://sluzby.heureka.cz/napoveda/xml-feed/ sekce DELIVERY"
          required
        />
        <NumberInput
          source="delivery_price_cod"
          helperText="Priplatek za dobirku; 0 - pokud je dobirka zdarma, nic - pokud nejde platit pri prevzeti zbozi"
          default={0}
        />
      </SimpleFormIterator>
    </ArrayInput>
    <ArrayInput source="ext_zbozicz_delivery">
      <SimpleFormIterator>
        <TextInput
          source="delivery_id"
          helperText="viz https://napoveda.seznam.cz/cz/specifikace-xml-feedu/#DELIVERY"
          required
        />
        <NumberInput
          source="delivery_price_cod"
          helperText="Priplatek za dobirku; 0 - pokud je dobirka zdarma, nic - pokud nejde platit pri prevzeti zbozi"
          default={0}
        />
      </SimpleFormIterator>
    </ArrayInput>
  </SimpleForm>
);

export const ShippingCreate = props => (
  <Create {...props}>
    <Form />
  </Create>
);

export const ShippingEdit = props => (
  <Edit {...props}>
    <Form />
  </Edit>
);
